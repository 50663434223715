import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { device } from '../device'
import { palette } from '../styles/theme'
import Layout from '../components/layout'
import SEO from '../components/seo'

const Title = styled.h1`
  margin: -48px 0 0 0;
  padding: 0;
  font-family: 'Jorge-Luis-Cafe-Bold';
  font-size: 1.875rem;
  line-height: 1.5;
  color: ${palette.color6};
  @media ${device.intermediate} {
    font-size: 2.25rem;
  }
  @media ${device.web} {
  }
`

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  return (
    <Layout isBlog={false} location={location} title={siteTitle}>
      <SEO title="404: Not Found" />
      <Title>404: Not Found</Title>
    </Layout>
  )
}

NotFoundPage.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
